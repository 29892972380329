import React from 'react';

const CTA = ()=>{
    return( <section id="cta" class="cta">
    <div class="container" data-aos="zoom-in">

      <div class="row">
        <div class="col-lg-9 text-center text-lg-left">
          <h3>Ready to Evolve?</h3>
          <p>Your Future in Banking Awaits, Schedule a Consultation Today. 
</p>
        </div>
        <div class="col-lg-3 cta-btn-container text-center">
          <a class="cta-btn align-middle" href="#contact">Contact Us</a>
        </div>
      </div>

    </div>
  </section>)
};

export default CTA;