import React from "react";

const AboutUs = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>About Us</h2>
        </div>

        <div className="row content">
          <div className="col-lg-6">
            <p>
              Prajna Labs was created by a team of experienced consultants with
              more than 50 cumulative years of successful project
              implementations and upgrades for core banking systems.
            </p>
            <ul>
              <li>
                <i className="ri-check-double-line"></i> Our uniqueness is based
                on our first-hand battle-field experience during different
                projects in more than 30 countries and various geographies.
              </li>
              <li>
                <i className="ri-check-double-line"></i> Our developer turned
                consultants have seasoned experience in understanding the
                requirements and specializes in paying attention to detail in
                designing and delivering designs and solutions that are scalable
                and future proof.
              </li>
              <li>
                <i className="ri-check-double-line"></i> In this world of New
                Normal, Prajna helps organizations to touch the lives of their
                ultimate customers with the plethora of “Digital Convenience” by
                implementing diversified palette of innovative products.
              </li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
              Our experience comprises of various skills covering the entire
              lifecycle of the implementation projects starting from initiation
              and analysis phases to testing, deployment, and completion.
            </p>
            <ul>
            <li>
                <i className="ri-check-double-line"></i> Our experienced Team with varied skills and relevant exposure shall work in tandem with Bank’s Team with the view deliver Quality in tandem.
              </li>
              <li>
                <i className="ri-check-double-line"></i> We go beyond conventional support. From Onsite Assistance to augmenting your project teams, we're your partners in innovation. Our On-the-job training empowers your Bank's IT Team, while our Solution Review adds extra value, ensuring your technology journey is truly exceptional.
              </li>
              <li>
                <i className="ri-check-double-line"></i>Our holistic approach includes in-depth solution analysis, strategic partnership for realizing intents, and a mixed presence model ensuring optimal solution delivery within agreed schedules.
              </li>
              </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutUs;
