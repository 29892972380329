import React from 'react';
const Skills = ()=>{
    return(
        <section id="skills" className="skills">
        <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Our Skills</h2>
        </div>

          <div className="row">
            <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
              <img src="assets/img/skills.png" className="img-fluid" alt=""/>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
              <h3>
              Key Proficiencies of Our Team</h3>
             <p className="font-bold">
             T24 - Specific Expertise:
              </p> 
  
              <div className="skills-content">
  
                <div className="progress">
                  <span className="skill">Arrangement Architecture (AA)<i className="val">80%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
  
                <div className="progress">
                  <span className="skill">Code Review and Optimisation<i className="val">100%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
  
                <div className="progress">
                  <span className="skill">Temenos Application Framework C (TAFC)<i className="val">90%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
  
                <div className="progress">
                  <span className="skill">Temenos application framework in Java (TAFJ)<i className="val">85%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>

                <div className="progress">
                  <span className="skill">Integrate | Relate | Infer | Share (IRIS)<i className="val">85%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div className="progress">
                  <span className="skill">Temenos Payments Hub (TPH)<i className="val">90%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div className="progress">
                  <span className="skill">Trade Finance<i className="val">95%</i></span>
                  <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
  
        </div>
      </section>
    )
};

export default Skills;