import React from 'react';
import ReactDOM from 'react-dom';
import Layout from './../components/layout'
import SEO from '../components/seo';
import Header from '../components/header';
import HeroBanner from '../components/hero';
// import Features from '../components/features';
import Clients from '../components/clients';
import AboutUs from '../components/about-us';
import WhyUs from '../components/why-us';
import Skills from '../components/skills';
import Services from '../components/services';
import CTA from '../components/cta';
import Portfolio from '../components/portfolio';
import Team from '../components/team';
import Contact from '../components/contact';
import Footer from '../components/footer';

const IndexPage = ({ location })=>{
  return (
    <Layout location={location}>
      <SEO title="Home" />
      <Header />
      <HeroBanner/>
      <main id="main">
      <Clients/>
      <AboutUs/>
      <WhyUs/>
      <Skills/>
      <Services/>
      <CTA/>
      {/* <Portfolio/>
      <Team/> */}
      <Contact/>
      </main>
      <Footer/>
     {/* <main className="site-wrapper-reveal">
        <HeroArea />
        <ServicesArea />
        <AboutArea />
        <ContactArea />
      </main>
      <Footer /> */}
    </Layout>
   )
}

export default IndexPage;