import React from "react";

const WhyUs = () => {
  return (
    <section id="why-us" className="why-us section-bg">
      <div className="container-fluid" data-aos="fade-up">
        <div className="section-title">
          <h2>Why Us</h2>
        </div>

        <div className="row">
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
            <div className="content">
              <h3>
              Choose us for a transformative partnership{" "}
                <strong>where innovation flourishes, and projects are delivered with precision and excellence.</strong>
              </h3>
              <p>
              We do not only understands your needs but also delivers solutions with unwavering dedication. Explore the myriad advantages of selecting our distinguished services.
              </p>
            </div>

            <div className="accordion-list">
              <ul>
                <li>
                  <a
                    data-toggle="collapse"
                    className="collapse"
                    href="#accordion-list-1"
                  >
                    <span>01</span> Specialized Experts in Temenos Solutions
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-1"
                    className="collapse show"
                    data-parent=".accordion-list"
                  >
                    <p>
                    With unmatched proficiency, we streamline and optimize Temenos solutions for seamless banking operations, innovation, and success. Partner with us to elevate your institution's potential and embrace the future of banking technology.
                    </p>
                  </div>
                </li>
                <li>
                  <a
                    data-toggle="collapse"
                    href="#accordion-list-2"
                    className="collapsed"
                  >
                    <span>02</span> Exposure to Multiple regions and countries{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-2"
                    className="collapse"
                    data-parent=".accordion-list"
                  >
                    <p>

                    With a global footprint, we bring extensive exposure across diverse regions and countries. We leverages this rich experience to tailor solutions that transcend borders, ensuring your institution's success resonates worldwide. Partner with us to harness the power of international expertise for your banking journey.                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-toggle="collapse"
                    href="#accordion-list-3"
                    className="collapsed"
                  >
                    <span>03</span> Clear Understanding of Banking Needs{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-3"
                    className="collapse"
                    data-parent=".accordion-list"
                  >
                    <p>
                    Our expertise ensures that every technological step we take enhances operational efficiency, customer experience, and strategic growth. We truly comprehends and caters to your banking aspirations.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-toggle="collapse"
                    href="#accordion-list-4"
                    className="collapsed"
                  >
                    <span>04</span> Passion and Commitment in Collaboration{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-4"
                    className="collapse"
                    data-parent=".accordion-list"
                  >
                    <p>
                    We wholeheartedly invest in every partnership, combining our expertise with your vision to achieve remarkable results. With unwavering dedication, we ensure that our collaboration fuels your institution's success and propels us towards shared achievements.                    </p>
                  </div>
                </li>

              </ul>
            </div>
          </div>

          <div
            className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
            style={{ backgroundImage: 'url("assets/img/why-us.png")' }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
