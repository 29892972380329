import React from "react";

const Services = () => {
  return (
    <section id="services" class="services section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2> Services</h2>
          
        </div>

        <div class="row">
          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="bx bx-analyse"></i>
              </div>
              <h4>
                <a href="">Business Analysis</a>
              </h4>
              <li>
                Business requirements workshops
                </li>
                <li>
                Preparations of Requirement Specifications & GAP Analysis
                </li>
                <li>
                High level Solution Design
                </li>
            </div>
          </div>

          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="bx bx-play-circle"></i>
              </div>
              <h4>
                <a href="">Test Management</a>
              </h4>
              <li>Test Strategy</li>
              <li>Test Planning</li>
              <li>Defect Management</li>
            </div>
          </div>

          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="bx bxs-cog"></i>
              </div>
              <h4>
                <a href="">Test Execution</a>
              </h4>
              <li>Test Execution</li>
              <li>Test Automation</li>
              <li>Issue Resolution </li>
            </div>
          </div>


          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="bx bxs-bank"></i>
              </div>
              <h4>
                <a href="">Model Bank Configurations</a>
              </h4>
              <li>Product Setup</li>
              <li>Rule Definition</li>
            </div>
          </div>

          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="bx bx-certification"></i>
              </div>
              <h4>
                <a href="">Training</a>
              </h4>
             <li>Training Strategy</li>
             <li>Training Delivery</li>
            </div>
          </div>

          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="bx bx-check-shield"></i>
              </div>
              <h4>
                <a href="">Health Checks</a>
              </h4>
              <li>Temenos T24 Health Check</li>
              <li>Recommendation on upgrade vs Re-implementation approach</li>
              <li>Temenos Infinity Health Check</li>
            </div>
          </div>



          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="bx bx-globe"></i>
              </div>
              <h4>
                <a href="">Software Selection</a>
              </h4>
             <li>Scope & requirements Definition</li>
             <li>RFP creation & Evaluation</li>
             <li>Vendor Assessment</li>
            </div>
          </div>
          <div
            class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div class="icon-box">
              <div class="icon">
                <i class="bx bx-layer"></i>
              </div>
              <h4>
                <a href="">Program Management</a>
              </h4>
             <li>PMO & Program Management</li>
             <li>Project setup & Mobilization</li>
             <li>Project Management</li>
            </div>
          </div>




        </div>
      </div>
    </section>
  );
};

export default Services;
