import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
//Fonts
import "../../static/assets/css/fonts.css";

//Vendor CSS Files
import "../../static/vendor/bootstrap/css/bootstrap.min.css";
import "../../static/vendor/icofont/icofont.min.css";
import "../../static/vendor/boxicons/css/boxicons.min.css";
import "../../static/vendor/remixicon/remixicon.css";
import "../../static/vendor/venobox/venobox.css";
import "../../static/vendor/owl.carousel/assets/owl.carousel.min.css";
import "../../static/vendor/aos/aos.css";

//Template CSS File
import "../../static/assets/css/style.css";
import { withPrefix, Link } from "gatsby";

const Layout = ({ children, location }) => {
  return (
    <div className="wrapper">
      <Helmet>
        <script
          src={withPrefix("vendor/jquery/jquery.min.js")}
          type="text/javascript"
        />
        <script
          src={withPrefix("vendor/bootstrap/js/bootstrap.bundle.min.js")}
          type="text/javascript"
        />
        <script
          src={withPrefix("vendor/jquery.easing/jquery.easing.min.js")}
          type="text/javascript"
        />
        <script
          src={withPrefix("vendor/php-email-form/validate.js")}
          type="text/javascript"
        />
        <script
          src={withPrefix("vendor/waypoints/jquery.waypoints.min.js")}
          type="text/javascript"
        />
        <script
          src={withPrefix("vendor/isotope-layout/isotope.pkgd.min.js")}
          type="text/javascript"
        />
        <script
          src={withPrefix("vendor/venobox/venobox.min.js")}
          type="text/javascript"
        />
        <script
          src={withPrefix("vendor/owl.carousel/owl.carousel.min.js")}
          type="text/javascript"
        />
        <script src={withPrefix("vendor/aos/aos.js")} type="text/javascript" />
        {/* <script src={withPrefix("assets/js/main.js")} type="text/javascript" />  */}
      </Helmet>
      {children}
      <div id="preloader"></div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
