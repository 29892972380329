import React from "react";
import { Link } from "gatsby";

const Header = () => {
  return (
    <React.Fragment>
      <button type="button" class="mobile-nav-toggle d-lg-none">
        <i class="icofont-navigation-menu"></i>
      </button>

      <header id="header" className="fixed-top ">
        <div className="container d-flex align-items-center">
          {/* <h1 className="logo mr-auto"><a href="index.html">Arsha</a></h1> */}
          {/* Uncomment below if you prefer to use an image logo */}
          <Link to="/" className="logo mr-auto">
            <img src="assets/img/logo.png" alt="" className="img-fluid" />
          </Link>

          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li className="active">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#about">About</Link>
              </li>
              <li>
                <Link to="/#why-us">Why US</Link>
              </li>
              <li>
                <Link to="/#skills">Skills</Link>
              </li>
              <li>
                <Link to="/#services">Services</Link>
              </li>
              <li>
                <Link to="/#contact">Contact</Link>
              </li>
            </ul>
          </nav>

          {/* <a href="#about" className="get-started-btn scrollto">Get Started</a> */}
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
