import React from 'react';

const Footer = ()=> {
    return(<footer id="footer">
    {/* <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>Join Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action="" method="post">
              <input type="email" name="email"/><input type="submit" value="Subscribe"/>
            </form>
          </div>
        </div>
      </div>
    </div> */}
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <img src="assets/img/logo_prajnalabs.png" alt="" className="img-fluid"/>

            <p>
                Workafella Business Centre-51, <br></br>
                Level 4,Tower-A,Rattha Tek Meadows, <br></br>
                Old Mahabalipuram Road, <br></br>
                Sholinganallur,Chennai, <br></br>
                Tamil Nadu,India, 600119 <br></br>
              <strong>Phone:</strong> +91 6302 636 663 <br></br>
              <strong>Email:</strong> surya@prajna-labs.com <br></br>
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>

            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#services">Business Analysis</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Test Management</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Model Bank Configurations</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Health Checks</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Software Selection</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Social Networks</h4>
            <p>
            Interested in learning more about our services? We invite you to connect with us on social media platforms. You can also contact us directly through our website to speak with a member of our team.
            </p>
            <div class="social-links mt-3">
              <a href="https://www.twitter.com/prajnalabs" target='_blank' class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="https://www.facebook.com/prajnalabs" target='_blank' class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="https://www.instagram.com/prajnalabs" target='_blank' class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="https://www.google-plus.com/prajnalabs" target='_blank' class="google-plus"><i class="bx bxl-skype"></i></a>
              <a href="https://www.linkedin.com/prajnalabs" target='_blank' class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="container footer-bottom clearfix">
      <div class="copyright">
        &copy; Copyright <strong><span>Prajna Labs</span></strong>. All Rights Reserved
      </div>
    </div>
  </footer>)
}

export default Footer;